<div class="hero-background" [ngStyle]="{'background-image': 'url(home_bg.png)'}">
    <div class="banner-section">
        <app-home-banner [pageType]="'homepage'" [BannerHeading]="bannerHeading"
            [BannerImage]="bannerImage" (searchEvent)="onSearchEvent($event)"></app-home-banner>

    </div>
</div>
<section class="chapter">
    <div class="chapter-list-container">
        <!-- chapter listing -->
        <app-chapter-list [chapter_list]="chapter_list"></app-chapter-list>
        <!-- chapter listing ends -->

        <!-- <div class="row">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h4 class="content-title">CLINICAL SCIENCE</h4>
                <ol class="main-list">
                    <li>Epidemiology
                        <ol>
                            <li><a href="/chapter">Epidemiology of Leprosy</a>
                                <span>(<a href="">JH Richardus</a>,<a href=""> E Ignotti</a>, <a href="">WCS Smith</a> )</span>
                            </li>
                            <li><a href="">Leprosy Control</a>
                                <span>(<a href="">JH Richardus</a>, <a href="">D Pahan</a>,<a href=""> RC Johnson</a>, <a href="">WCS Smith</a> )</span>
                            </li>
                        </ol>
                    </li>
                    <li>Clinical Aspects
                        <ol>
                            <li><a href="">Clinical Diagnosis of Leprosy</a>
                                <span>( <a href="">JH Richardus</a>, <a href="">E Ignotti</a>,<a href=""> WCS Smith</a> )</span>
                            </li>
                            <li><a href="">Leprosy Reactions</a>
                                <span>( <a href="">SL Walker</a> )</span>
                            </li>
                            <li><a href="">Differential Diagnosis of Leprosy</a>
                                <span>(<a href="">SL Moschella</a>, <a href="">V Garcia Albea</a> )</span>
                            </li>
                            <li><a href="">Pathogenesis and Pathology of Leprosy</a>
                                <span>(<a href="">DM Scollard</a>)</span>
                            </li>
                            <li><a href="">Neurological Manifestations of Leprosy</a>
                                <span>(<a href="">J Vijayan</a>, <a href="">EP Wilder-Smith</a>)</span>
                            </li>
                            <li><a href="">Treatment of Leprosy</a>
                                <span>(<a href="">DN Lockwood</a>)</span>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h4 class="content-title">BASIC SCIENCE</h4>
                <ol class="main-list second-column"> 
                    <li>Microbiology
                        <ol>
                            <li><a href="">The Physiology of Mycobacterium leprae</a>
                                <span>(<a href="">PJ Brennan</a>,<a href="">JS Spencer</a> )</span>
                            </li>
                            <li><a href="">Anti-Leprosy Drugs: Modes of Action and Mechanisms of Resistance in Mycobacterium leprae</a>
                                <span>(<a href="">E Cambau</a>,<a href="">DL Williams</a> )</span>
                            </li>
                            <li><a href="">Cultivation and Viability Determination of Mycobacterium leprae</a>
                                <span>(<a href="">R Lahiri</a>,<a href=""> LB Adams</a>)</span>
                            </li>
                        </ol>
                    </li>
                    <li>Immunology
                        <ol>
                            <li><a href="">Innate Immunity</a>
                                <span>(<a href="">MO Moraes</a>, <a href="">LRB Silva</a>,<a href="">RO Pinheiro</a> )</span>
                            </li>
                            <li><a href="">Adaptive Immune Response in Leprosy</a>
                                <span>(<a href="">MO Moraes</a>,<a href="">LRB Silva</a> , <a href="">RO Pinheiro</a>)</span>
                            </li>
                            <li><a href="">Vaccines for Prevention of Leprosy</a>
                                <span>(<a href="">SG Reed</a>,<a href="">MS Duthie</a> )</span>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </div> -->
        
    </div>
</section>