import { Component, EventEmitter, Output, OnDestroy, HostListener, Input, ChangeDetectorRef, ElementRef, ViewChild, OnInit, PLATFORM_ID, AfterViewInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArticleService } from '../../services/article.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { SearchContextService } from '../../services/search-context-service';
import { SharedService } from '../../services/shared.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
    @ViewChild('searchFormDiv') searchFormDiv: ElementRef;
    @ViewChild('clearBtn') clearBtn: ElementRef
    searchForm: FormGroup;
    @Output() searchEvent = new EventEmitter<string>();

    @Input() category: string = '';// Add this line to accept category from parent

    @Input() contentType: any;
    @Input() surgicalSpecialty: any;
    @Input() pageType: any;

    showSearchSuggesion = false;
    ShowTextLoader: boolean;
    searchResults: any[] = [];
    search_list:any[]=[];
    articles:any[]=[];
    lastPart: string = '';/*  */
    private routeSubscription: Subscription;
    firstPart: string;
    search: boolean = false;
    page: string;
    private readonly searchContextService = inject(SearchContextService);
    length = 10;
    start = 0;
    journalid: string = '';
    isInitialLoad: boolean = true; // New flag for initial load
    resultBoxwidth = 0;
    clearBtnWidth = 0;
    allarticles: boolean = false;
    constructor(
        private fb: FormBuilder,
        private articleService: ArticleService,
        private route: ActivatedRoute,
        private router: Router,
        private sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef,
        private sharedService: SharedService
    ) {
        this.searchForm = this.fb.group({
            searchValue: ['']
        });
    }

    ngOnInit() {
        this.category = this.category || "All";
        this.journalid = this.sharedService.getJournalId();
        this.searchForm.get('searchValue')?.setValue('');
        this.searchResults = [];
        this.search = false;
        this.showSearchSuggesion = false;
        this.isInitialLoad = true; // Set to true on initial load

        this.route.queryParams.subscribe(params => {
            const searchValue = params['search'] || '';
            if (searchValue.length > 0) {
                this.searchForm.get('searchValue')?.setValue(searchValue);
                this.search = true;
                this.searchEvent.emit(searchValue);
                // this.showSearchSuggestions(searchValue);
                this.showSearchSuggesion = false;
            }
            this.isInitialLoad = false; // Set to false after processing query params
        });

        this.routeSubscription = this.route.url.subscribe(urlSegments => {
            if (urlSegments.length == 3) {
                this.category = urlSegments[0]?.path;
            }
            else if (urlSegments.length > 3) {
                this.category = urlSegments[1]?.path;
            }
            else if (this.category == undefined || this.category == "All") {
                this.category = "all-articles"

            }
            else if (this.category == "all-articles") {
                this.allarticles = true;
            } else if (urlSegments[0].path == "all-articles") {
                this.allarticles = true;
            }
            else {
                this.category = this.category;
                this.allarticles = false;
            }
        });


    }
    ngAfterViewInit(): void {
        this.resultBoxwidth = this.searchFormDiv?.nativeElement.offsetWidth;
        this.clearBtnWidth = this.clearBtn?.nativeElement.offsetWidth;
        this.cdr.detectChanges();
    }
    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }
    @HostListener('window:resize', [])
    onWindowResizel(): void {
        this.resultBoxwidth = this.searchFormDiv?.nativeElement.offsetWidth;
        this.clearBtnWidth = this.clearBtn?.nativeElement.offsetWidth;
    }
    resetSearch() {
        this.searchForm.get('searchValue')?.setValue('');
        this.showSearchSuggesion = false;
        this.searchResults = [];
        this.searchEvent.emit('');
    }

    onEnterPressed(event: any) {
        const searchValue = this.searchForm.get('searchValue')?.value || '';
        this.searchForm.get('searchValue')?.setValue(searchValue); // Prevents emitting a valueChanges event

        if (searchValue.length >= 3) {
            this.searchArticles(event); // Pass the event here
            this.searchContextService.setSearchContent(searchValue);
        }
    }
    searchArticles(event: any) {
        
        this.showSearchSuggesion = false;
        const searchValue = this.searchForm.get('searchValue')?.value || '';
        this.searchContextService.setSearchContent(searchValue);

        if (searchValue.length >= 3) {
            this.showSearchSuggesion = false; // Hide suggestions before navigation
            if (this.allarticles == true) {
                this.router.navigate(['/academy', 'all-articles', 'page', '1'], { queryParams: { search: searchValue } });
                this.searchEvent.emit(searchValue);

            } else if (this.pageType == "homepage" && this.category != "all-articles") {

                this.router.navigate(['/academy', this.category, 'page', '1'], { queryParams: { search: searchValue } });
                // this.searchEvent.emit(searchValue);

            }
            else if (this.pageType == "listpage" && this.category != "all-articles") {

                this.router.navigate(['/academy', this.category, 'page', '1'], { queryParams: { search: searchValue } });
                // this.searchEvent.emit(searchValue);

            }
            else {
                this.router.navigate(['/academy', 'all-articles', 'page', '1'], { queryParams: { search: searchValue } });

            }



            this.searchForm.get('searchValue')?.setValue(searchValue);
            this.searchEvent.emit(searchValue);
            this.showSearchSuggesion = false; // Hide suggestions after navigation (if needed)

        }
        else if (this.category === 'all-articles' && !searchValue) {
            this.router.navigate(['/academy', 'all-articles', 'page', '1']);
        }
        else {
            this.search = false;
            this.showSearchSuggesion = false;
        }
    }


    showSearchSuggestions(event:Event){
        const input = event.target as HTMLInputElement;
        const searchValue = this.searchForm.get('searchValue')?.value || '';
        const params: any = {
            searchValue:searchValue
        };
        this.articleService.getArticleListLepra(this.journalid, params).subscribe(result => {
            this.articles = [];
            this.search_list = result.data;
            this.search_list.forEach(ele => {
                this.articles = [...this.articles, ...ele.parts[0].articles];
                this.showSearchSuggesion = true;
            });
        });
        
        // this.searchEvent.emit(searchValue);

    }



    // showSearchSuggestions(event: Event) {

    //     const input = event.target as HTMLInputElement;
    //     const searchValue = this.searchForm.get('searchValue')?.value || '';;
        
    //     this.searchContextService.setSearchContent(searchValue);

    //     if (searchValue.length === 0) {
    //         this.showSearchSuggesion = false;
    //         this.searchResults = [];
    //         this.searchEvent.emit('');
    //     }
    //     else if (searchValue.length == 1) {
    //         this.search = true; // Show loader
    //         // if (this.category) {
    //         //   this.filterDataApi(this.journalid)
    //         // }
    //     }
    //     else if (searchValue.length >= 3) {
    //         this.ShowTextLoader = true;
    //         this.search = true; // Show loader
    //         if (this.category) {
    //             this.filterDataApi(this.journalid, event)
    //             this.showSearchSuggesion = false;

    //         }
    //     }
    //     else {
    //         this.search = false;

    //     }
    // }

    // filterDataApi(journalid: string, event: any): void {
    //     const content_type = this.searchContextService.selectedContentType();
    //     const surgical_speciality = this.searchContextService.selectedSpecialities();
    //     const searchValue = this.searchContextService.searchContent()
        

    //     const params: any = {
    //         category: this.category,
    //         content_type: JSON.stringify(content_type),
    //         surgical_speciality: JSON.stringify(surgical_speciality),
    //         searchValue: searchValue
    //     };

    //     const articleSub = this.articleService.getArticleList(journalid, params).subscribe(result => {
    //         this.ShowTextLoader = false;
    //         this.searchResults = result.data;
    //         this.route.queryParams.subscribe(params => {
    //             const searchValue = params['search'] || '';
    //             if (searchValue && event.inputType != "insertText") {
    //                 this.showSearchSuggesion = false;

    //             }
    //             else {
    //                 this.showSearchSuggesion = true;

    //             }
    //             this.searchEvent.emit(searchValue);
    //         });
    //     },
    //         (error) => {
    //             this.ShowTextLoader = false;
    //         })
    // }
    onTextboxClicked(): void {
        const searchValue = this.searchForm.get('searchValue')?.value || '';

        if (searchValue.length >= 3) {
            this.showSearchSuggesion = true;
        } else {
            this.showSearchSuggesion = false; // Optionally hide suggestions if less than 3 characters
        }
    }
    highlightMatch(text: string, searchValue: string): SafeHtml {
        if (!text) {
            return this.sanitizer.bypassSecurityTrustHtml('');
        }
        if (!searchValue) {
            return this.sanitizer.bypassSecurityTrustHtml(text);
        }
        const regex = new RegExp(searchValue, 'gi');
        const highlightedText = text.replace(regex, (match) => `<span style="background-color: yellow;">${match}</span>`);
        return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
    }

    formatAuthors(authors: any[]): string {
        return authors.map(author => author).join(', ');
    }

    formatDate(pub_date: any): string {
        if (pub_date && pub_date.preprint) {
            const { day, month, year } = pub_date.preprint;
            if (day && month && year) {
                return `${day} ${this.getMonthName(month)} ${year}`;
            }
        }
        if (pub_date && pub_date.collection) {
            const { day, month, year } = pub_date.collection;
            if (day && month && year) {
                return `${day} ${this.getMonthName(month)} ${year}`;
            }
        }
        return 'Date not available';
    }

    getMonthName(month: number): string {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[month - 1];
    }

    clearSearch() {
        this.searchForm.get('searchValue')?.setValue('');
        this.search = false;
        this.showSearchSuggesion = false;
        // this.router.navigate(['/academy', this.category, 'page', '1']);

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            replaceUrl: true,
        });
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
        if (!(event.target as HTMLElement).closest('.search-result-section') && !(event.target as HTMLElement).closest('.search-form-control')) {
            this.showSearchSuggesion = false;
        }
    }

    navigateToArticle(article_link: any, category_heading: any) {
        if (this.category === "all-articles") {
            this.router.navigate(['/academy', category_heading, article_link]);
        } else {
            this.router.navigate(['/academy', this.category, article_link]);
        }

    }
}
