<form [formGroup]="searchForm">
	<div class="row search-section" #searchFormDiv>
		<div
			class="col-xxl-10 col-xl-9 col-lg-9 col-md-8 col-sm-10 col-xs-10 ps-0 pe-xxxl-1 pe-xl-1 pe-lg-1 pe-md-1 pe-sm-1 pe-xs-1">
			<div class="input-group input-group-lg position-relative">
				<span class="input-group-text search-text-box" id="basic-addon1">
					<fa-icon [icon]="['fas','magnifying-glass']" class="search-icon"></fa-icon>
				</span>

				<input type="text" class="form-control search-form-control" placeholder="Search..." aria-label="Search"
					aria-describedby="basic-addon1" formControlName="searchValue" (keyup.enter)="onEnterPressed($event)"
					(input)="showSearchSuggestions($event)" (click)="onTextboxClicked()"  />
				<!-- Loader -->

				<div *ngIf="ShowTextLoader==true" class="spinner-container" [ngStyle]="{'right':clearBtnWidth+'px'}">
					<div class="spinner-border" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				</div>
		
				<!-- Clear Button -->
				<button *ngIf="search" type="button" class="clear-button" (click)="clearSearch()" #clearBtn>
					<fa-icon [icon]="['fas', 'eraser']" class="search-button-icon "></fa-icon>
					<span clas="clear-button-icon">Clear</span>
				</button>
			</div>



			<div *ngIf="showSearchSuggesion && articles.length > 0" class="search-result-section" [ngStyle]="{'width': resultBoxwidth + 'px'}">
				<div>
					<ul class="p-0">
						<li *ngFor="let result of articles; let isLast = last" class="search-result-li">
							<div class="d-flex flex-column custom-margin">
								<a class="search_link" [routerLink]="['/',result.part_link,result.heading_link, result.article_link]"><p class="ml-3 search-title" 
									[innerHTML]="result.article_title">
								</p></a>
								<!-- <p class="ml-3 search-author" [ngStyle]="{'font-size': '12px'}"
									[innerHTML]="highlightMatch(formatAuthors(result.authors), searchForm.get('searchValue')?.value)">
								</p> -->
								<!-- <p class="ml-3 search-pub-date">
									<fa-icon [icon]="['fas','calendar-days']" class="icon-date pe-2"></fa-icon>
									<span [innerHTML]="result.published_online"></span>
								</p> -->
								<!-- <div class="ml-3 card-tag">
									<a href="#" class="tag-content badge">{{ result.surgical_speciality }}</a>
									<a href="#" class="tag-content badge">{{ result.content_type }}</a>
								</div> -->
							</div>
							<!-- <hr *ngIf="!isLast" class="search-result-divider"> -->
						</li>
					</ul>
				</div>
			</div>

			<div *ngIf="!showSearchSuggesion&&!ShowTextLoader && !isInitialLoad && searchResults.length === 0 && searchForm.get('searchValue')?.value?.length > 0"
				class="search-result-section" [ngStyle]="{'width': resultBoxwidth + 'px'}">
				<div>
					<ul class="p-0">
						<li class="search-result-li">
							<div class="d-flex justify-content-center">
								<p class="mt-3">No results found</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<!-- <div *ngIf="!ShowTextLoader && searchResults.length === 0 && searchForm.get('searchValue')?.value?.length === 0" class="search-result-section">
        <ul class="p-0">
          <li class="search-result-li">
            <div class="d-flex justify-content-center">
              <p class="mt-3">No results found</p>
            </div>
          </li>
        </ul>
      </div> -->


			<!-- <div *ngIf="!ShowTextLoader && searchResults.length === 0 && searchForm.get('searchValue')?.value?.length ==0" class="search-result-section">
        <ul class="p-0">
          <li class="search-result-li">
            <div class="d-flex justify-content-center">
              <p class="mt-3">No results found</p>
            </div>
          </li>
        </ul>
      </div> -->
		</div>
		<div
			class="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-2 col-xs-2 pe-0 ps-xxl-2 ps-xl-2 ps-lg-2 ps-md-1 ps-sm-1 ps-xs-1">
			<div class="d-flex flex-row">

				<button type="submit" class="btn btn-primary btn-lg search-button mt-0"
					(click)="searchArticles($event)">
					<fa-icon [icon]="['fas', 'magnifying-glass']" class="search-button-icon"></fa-icon>
					<span class="d-xs-none  d-sm-none d-md-inline custom-search ps-1">Search</span>
				</button>
			</div>
		</div>
	</div>
</form>