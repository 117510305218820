<div *ngIf="!ChapterLoading ; else NotChapterLoading">
    <div *ngIf="chapter_list.length > 0" class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">

        <!-- <div *ngIf="cseBjs?.length > 0"
                class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">
                <app-article-card-list *ngFor="let article of cseBjs; let i = index" [article]="article"
                    class="article-listing-card"></app-article-card-list>
            </div> -->
        <div *ngFor="let chapter of chapter_list">
            <h4 class="content-title">{{chapter.heading}}</h4>
    
            <ul class="main-list no-marker">
                <li *ngFor="let parts of chapter.parts">
                    {{ parts.part_sort }}. {{ parts.part }}
                    <ul class="no-marker">
                        <li *ngFor="let articles of parts.articles">
                            {{ parts.part_sort }}.{{ articles.article_sort }}<a [routerLink]="['/', chapter.heading_id, parts.part_id, articles.article_link]">
                                {{articles.article_title }}
                            </a>
                            <span [innerHTML]="getAuthorlist(articles.authors)">
                            </span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    
    </div>
    <div *ngIf="chapter_list.length == 0"
        class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">
        <div>
            <h4 class="content-title">No data found</h4>
        </div>
    </div>
</div>
<ng-template #NotChapterLoading>
    <div >
        <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">
            <div  *ngFor="let eachdata  of  loadingArray ">
                <h4 class="content-title skeleton skeleton-heading  skeleton-text "></h4>
        
                <ul class="main-list no-marker" >
                    <li  *ngFor="let eachdata  of  loadingArray ">
                        
                        <ul class="no-marker">
                            <li *ngFor="let eachdata  of  loadingArray " >
                                <p class="skeleton skeleton-text ">
                                   
                                </p>
                                <span class="skeleton skeleton-text ">
                                </span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        
        </div>
        <div *ngIf="chapter_list.length == 0"
            class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 g-4">
            <div>
                <h4 class="content-title">No data found</h4>
            </div>
        </div>
    </div>
</ng-template>